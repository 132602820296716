import * as types from '../actionTypes';

const initialState = {
    user: [],
    loading: false,
    apiSuccess: undefined,
}

function userReducer(state = initialState, action) {
    switch (action.type) {
        case types.USERPROFILE_REQUEST:
            return { ...state, loading: true }
        case types.USERPROFILE_SUCCESS:
            return { user: action.user, loading: false, apiSuccess: true }
        case types.USERPROFILE_FAILURE:
            return { user: [], apiSuccess: false, loading: false }
        default:
            return state
    }
}


export default userReducer