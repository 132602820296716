import * as types from "../actionTypes"
import axios from "axios";
import APIConstants from "../../utils/APIConstants";

export const userCourseSeatRequest = () => {
    return {
        type: types.USERCOURSESEAT_REQUEST
    }
}

export const userCourseSeatSuccess = (userCrsMap) => {
    return {
        type: types.USERCOURSESEAT_SUCCESS,
        userCrsMap: userCrsMap
    }
}

export const userCourseSeatFailure = () => {
    return {
        type: types.USERCOURSESEAT_FAILURE
    }
}

export const getUserCoursesSeatMap = (user_id) => {
    return (dispatch) => {
        dispatch(userCourseSeatRequest())
        axios.get(APIConstants.API_DESTINATION + APIConstants.CRS_USER_SEAT + '?user_id=' + user_id)
            .then(res => {
                if (res.data.status == "success") {
                    dispatch(userCourseSeatSuccess(res.data.data));
                } else {
                    dispatch(userCourseSeatFailure());
                }
            })
            .catch(err => {
                dispatch(userCourseSeatFailure())
            })

    }
}

export const clearUserCrsSeatState = () => {
    return {
        type: types.CLEARUSRCRSMAPSTATE
    }
}