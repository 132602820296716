
import * as types from '../actionTypes';

const initialState = {
    user: [],
    loading: false,
    apiSuccess: undefined,
    isLoggedIn: false
}

function userReducer(state = initialState, action) {

    switch (action.type) {
        case types.AUTH_REQUEST:
            return { ...state, loading: true }
        case types.AUTH_SUCCESS:
            return { user: action.user, loading: false, apiSuccess: true, isLoggedIn: true }
        case types.AUTH_FAILURE:
            return { user: [], apiSuccess: false, loading: false, isLoggedIn: false }
        case types.LOGOUT:
            return { user: [], isLoggedIn: false,apiSuccess : undefined }
        case types.UPDATEUSERPROFILE_DTLS : 
            return { user : action.user,loading:false,apiSuccess: true,isLoggedIn : true}
        default:
            return state
    }
}


export default userReducer
