import * as types from '../actionTypes';

const initialState = {
    userCrsSeatMap: [],
    loading: false,
    apiSuccess: undefined
}

function userCourseSeatReducer(state = initialState, action) {
    switch (action.type) {
        case types.USERCOURSESEAT_REQUEST:
            return { ...state, loading: true }
        case types.USERCOURSESEAT_SUCCESS:
            return { userCrsSeatMap: action.userCrsMap, loading: false, apiSuccess: true}
        case types.USERCOURSESEAT_FAILURE:
            return { userCrsSeatMap: [], apiSuccess: false, loading: false }
        case types.CLEARUSRCRSMAPSTATE :
            return { userCrsSeatMap: [], apiSuccess: undefined, loading: false }
        default:
            return state
    }
}


export default userCourseSeatReducer