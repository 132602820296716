import * as types from "../actionTypes"
import axios from "axios";
import APIConstants from "../../utils/APIConstants";

export const getMyCourseListRequest = () => {
    return {
        type: types.MYCOURSELIST_REQUEST
    }
}

export const getMyCourseListSuccess = (courseList) => {
    return {
        type: types.MYCOURSELIST_SUCCESS,
        courseList: courseList
    }
}

export const getMyCourseListFailure = () => {
    return {
        type: types.MYCOURSELIST_FAILURE
    }
}

export const getMyCourseList = (user_id)=>{
    return(dispatch)=>{
        dispatch(getMyCourseListRequest())
        axios.get(APIConstants.API_DESTINATION+APIConstants.GETMYCOURSELIST+'?user_id='+user_id)
        .then(res=>{
            if(res.data.status == "success"){
               dispatch(getMyCourseListSuccess(res.data.data));
            }else{
                dispatch(getMyCourseListFailure());
            }
        })
        .catch(err => {
            dispatch(getMyCourseListFailure())
        })

    }
}

export const clearMyCourseState = () =>{
    return {
        type : types.CLEARMYCOURSESTATE
    }
}