import * as types from '../actionTypes';

const initialState = {
    purchasedCrs: [],
    loading: false,
    apiSuccess: undefined
}

function pruchasedHitsoryReducer(state = initialState, action) {
    switch (action.type) {
        case types.PURCHASEDCRS_REQUEST:
            return { ...state, loading: true }
        case types.PURCHASEDCRS_SUCCESS:
            return { purchasedCrs: action.purchasedCrsList, loading: false, apiSuccess: true}
        case types.PURCHASEDCRS_FAILURE:
            return { purchasedCrs: [], apiSuccess: false, loading: false }
        case types.CLEARPURCHASESTATE:
            return { purchasedCrs: [], apiSuccess: undefined, loading: false }
        default:
            return state
    }
}


export default pruchasedHitsoryReducer