
import * as types from '../actionTypes'

const initialState = {
    courses: [],
    loading: false,
    apiSuccess: undefined
}

function fetchAllCoursesReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_COURSES_REQUEST:
            return { ...state, loading: true }
        case types.FETCH_ALL_SHOPIFY_COURSES_SUCCESS:
            return { courses: action.payload, loading: false, apiSuccess: true }
        case types.FETCH_COURSES_FAILURE:
            return { courses: [], apiSuccess: false, loading: false }
        default:
            return state
    }
}

export default fetchAllCoursesReducer