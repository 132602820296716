
import axios from "axios";
import { API_URL } from '../config';

/** Adding content into the obj  */

export const generateCourseContent = (cecrsrack) =>{
        cecrsrack.forEach((ca)=>{
            ca.sc.forEach((sc)=>{
                sc.courses.forEach((cr)=>{
                    cr !== undefined && getCourseObj(cr)
                })
            })
        });
    }
    
    const getCourseObj = (crItem) =>{
        var d = String(crItem.cmsid);
        if(d.indexOf(',') > 0 ){
            var ids = String(crItem.cid).split(',')
            var crs = d.split(',');
            if(!crItem.content){
                crItem.content = []
                crs.forEach((handle,index)=>{
                    try {
                        apiRequester(crItem,handle,ids[index])
                    }
                    catch (e) {
                        console.error(e);
                    }
                });
            }
        }
    }
    
    const apiRequester = (crItem,handle,id) =>{
        axios.get(`${API_URL}/courses/${handle}`)
        .then(res => {
            res.data.data.attributes['cid'] = Number(id);
            const ar = crItem.content.filter((el)=>{
               return el.id === res.data.data.attributes.id
            });
            if(crItem.cid==="455,456,457,458"){
                console.log("data")
            }
                crItem.content.push(res.data.data.attributes);
        })
        .catch(e => {
            console.log("======== error ======>",e)
            console.log(e)
        })
    }

   
    