import cecrsrack from './cecourseracking.json'
import professionalCollection from './ce-std-jsons/professional/professionalCollection.json';
import practitionerCollection from './ce-std-jsons/practitioner/practitionerCollection.json';
import companionCollection from './ce-std-jsons/companion/companionCollection.json';
import { useSelector, useDispatch } from "react-redux";
import { Utility} from '../utils/Utility'
export const CECourseRack = () => {

    let courselist = [];
    const courseOverview = professionalCollection.data.concat(practitionerCollection.data,companionCollection.data)
    const getCourseRack = (shopify, recharge) => {
        const rclist = {};
        const splist = {};
        recharge.forEach(rc => { rclist[rc.product_id] = Object.assign({ shopify: null }, rc) })
        shopify.forEach(sp => {
            if (rclist[sp.id] !== undefined) { rclist[sp.id].shopify = sp }
        })
        courselist = Object.values(rclist);

        let courseData = cecrsrack;
        const category = {};
        const subcategory = {};
        const courserack = {};

        courseData.category.forEach(c => { category[c.id] = c; })
        courseData.subcategory.forEach(sc => { subcategory[sc.id] = sc; })


        for (const [key, value] of Object.entries(courseData.mapping)) {

            // const courserack = {}
            if (key === window.PARTNER_TYPE)
                value.forEach(crs => {
                    let crschop = (crs.split('_'))
                    let cat = courserack[crschop[0]]

                    if (cat === undefined) {
                        courserack[crschop[0]] = Object.assign({ sc: {} }, category[crschop[0]])
                    }

                    let sbcat = courserack[crschop[0]].sc[crschop[1]]

                    if (sbcat === undefined) {
                        courserack[crschop[0]].sc[crschop[1]] = Object.assign({ courses: {} }, subcategory[crschop[1]])
                    }

                    let cours = courserack[crschop[0]].sc[crschop[1]].courses[crschop[2]]

                    if (cours === undefined) {
                        courserack[crschop[0]].sc[crschop[1]].courses[crschop[2]] =
                            Object.assign(getProduct(crschop[2]), getCourseOverview((courseData.courses[crschop[2]] )), courseData.courses[crschop[2]]);
                        courserack[crschop[0]].sc[crschop[1]].courses[crschop[2]]["subCategory"] = crschop[1];



                    }
                })
        }
        const fs = Object.values(courserack)
        fs.map(f => {
            f.sc = Object.values(f.sc);
            f.sc.map(c => {
                c.courses = Object.values(c.courses)
                if(c.courses !== undefined)
                {
                    if(f.id === 3 && c.courses.length > 1 && window.PARTNER_TYPE !== 'ioeit')
                        swapOrder(c.courses);
                    if(window.PARTNER_TYPE === 'ioeit' && c.id === 29)
                        swapOrder(c.courses);
                    
                }
                
                return c;
            })
            return f;
        })
        Utility().swap(window.PARTNER_TYPE,fs);
        return fs;


    }

    /* Added this to swap the HR courses 1- Dual Use id 344 and 2- Defence id 345*/
    const swapOrder = (courseObj) =>{
            var temp = courseObj[0];
            courseObj[0] = courseObj[1];
            courseObj[1] = temp;
    }


    const getProduct = (sku) => {

        const prds = courselist.filter(c => {
            return (sku) === (c.shopify.variants[0].sku)
        })

        if (prds.length === 2) {
            // const subsinterval = prds[0].subscription_defaults.order_interval_unit;
            // const subscriptions = subsinterval === "Months" ? { monthly: prds[1], annually: prds[0] } : { monthly: prds[0], annually: prds[1] }
            // return subscriptions;

            let subscriptions = {};
            prds.forEach(el=>{
                if(el.subscription_defaults.order_interval_unit === "Months" || el.subscription_defaults.order_interval_unit === "month")
                    subscriptions.annually =  el;
                else
                    subscriptions.monthly =  el;
            })
            return subscriptions;
        }
        else if (prds.length === 1) {
            return { annually: prds[0] }
        }
        else {
            // do nothing
        }
        return {}
    }

    const getCourseOverview = (course) => {
        /**
         * only Jurisdiction matched
         * need to confirm about the configuration cherry pick from the [c] in tree data
         */
        let overview = {};
        const cid = String(course.cid);
        const configs = String(course.config).indexOf('_') > -1 ? course.config.split('_') : [0];
        const crsIds  = cid.indexOf(',') > -1 ? cid.split(',') : [cid];
        const overviewList = courseOverview.filter((item)=>{return   ( crsIds.find((f) => { return f === String(item.cid) }) ) !== undefined ? item : undefined  });

        if(overviewList.length >= 4 )    {
            overview = overviewList.find((o) => { return Number(o.jrs) === Number(configs[0]) })
        }
        else if(overviewList.length >= 2)   {
            /**
             * need to be more specific with the values for configs[0] as Jurisdiction, and search in the result array with that
             * overviewList.find((o) => { return Number(o.jrs) === Number(configs[0]) })
             */
            overview = overviewList[1]
        }
        else    {
            overview = overviewList[0]
        }
        // console.log(overview, configs)
        return overview;

    }

    return {
        getCourseRack
    }
}

export default CECourseRack;