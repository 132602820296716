

import * as types from "../actionTypes";
import axios from "axios";
import APIConstants from "../../utils/APIConstants";

// ref - https://exchangeratesapi.io/

export const fetchCurrencyExchangeRatesRequest = () => {
    return {
        type: types.FETCH_CURRENCY_EXCHANGE_RATES_REQUEST
    }
}

export const fetchCurrencyExchangeRatesSuccess = currencyExchangeRatesObj => {
    return {
        type: types.FETCH_CURRENCY_EXCHANGE_RATES_SUCCESS,
        payload: currencyExchangeRatesObj
    }
}

export const fetchCurrencyExchangeRatesFailure = () => {
    return {
        type: types.FETCH_CURRENCY_EXCHANGE_RATES_FAILURE
    }
}

export const setSelectedCurrency = (selectedCurrencyCode, selectedCFlag) => {
    return {
        type: types.SET_SELECTED_CURRENCY,
        payload: selectedCurrencyCode,
        flag: selectedCFlag
    }
}

export const fetchCurrencyExchangeRatesActions = () => {
    const currencyData = typeof window !== "undefined" && window.sessionStorage.getItem('currencydata');
    return (dispatch) => {
        dispatch(fetchCurrencyExchangeRatesRequest())
        if(currencyData === undefined || currencyData === null){
            axios.get(`${APIConstants.CURRENCY_EXCHANGE_RATES}/latest/USD`)
            .then(res => {
                const currencyExchangeRatesObj = res.data.conversion_rates;
                // console.log("currencyExchangeRatesObj",currencyExchangeRatesObj);
                if (typeof window !== "undefined"){
                    window.sessionStorage.setItem('currencydata', JSON.stringify(currencyExchangeRatesObj));
                }
                dispatch(fetchCurrencyExchangeRatesSuccess(currencyExchangeRatesObj))
            })
            .catch(err => {
                console.log(err)
                dispatch(fetchCurrencyExchangeRatesFailure())
            })
        }
        else{
            dispatch(fetchCurrencyExchangeRatesSuccess(currencyData))
        }
       
    }
}
//export const fetchCurrencyExchangeRatesActions = () => {
//     return (dispatch) => {
//         dispatch(fetchCurrencyExchangeRatesRequest())
//         axios.get(`${APIConstants.CURRENCY_EXCHANGE_RATES}/latest?symbols=USD,GBP,EUR&base=USD`)
//             .then(res => {
//                 const currencyExchangeRatesObj = res.data.rates;
//                 dispatch(fetchCurrencyExchangeRatesSuccess(currencyExchangeRatesObj))
//             })
//             .catch(err => {
//                 console.log(err)
//                 dispatch(fetchCurrencyExchangeRatesFailure())
//             })
//     }
// }