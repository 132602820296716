import * as types from '../actionTypes'

const initialState = {
    category: {},
    section: {}
}


function filterViewReducer ( state = initialState, action ) {
    switch ( action.type ) {
        case types.ACTIVE_CATEGORY:
            return { ...state, category: { ...action.payload } }
        case types.ACTIVE_SECTION:
            return { ...state, section: { ...action.payload } }
        default:
            return state
    }
}





export default filterViewReducer