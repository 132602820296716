import * as types from '../actionTypes'

const initialState = {
    courseObj: [],
    loading: false,
    apiSuccess: false
}

function fetchCourseDetailReducer(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_COURSE_DETAIL_REQUEST:
            return { ...state, loading: true }
        case types.FETCH_COURSE_DETAIL_SUCCESS:
            return { courseObj: action.payload, loading: false, apiSuccess: true }
        case types.FETCH_COURSE_DETAIL_FAILURE:
            return { courseObj: [], apiSuccess: false, loading: false }
        default:
            return state
    }
}


export default fetchCourseDetailReducer