import * as types from '../actionTypes'

const initialState = {
    bannerDisplayed:true,
    modalDisplayed: false,
    modalType: "",
    modalData: {},
    chkbtn: false

}

function otherReducer(state = initialState, action) {
    switch (action.type) {
        case types.CLOSE_TOP_BANNER:
            return { ...state, bannerDisplayed: false }
        case types.OPEN_MODAL:
            return { ...state, modalDisplayed: true, modalType: action.payload.modalType, chkbtn:action.payload.chkbtn, modalData: action.payload.modalData }
        case types.CLOSE_MODAL:
            return { ...state, modalDisplayed: false, modalType: "", modalData: {} }
        case types.ERROR_MODAL:
            return { ...state, modalDisplayed: true, modalType: action.payload.modalType, modalData: action.payload.modalData }
        default:
            return state
    }
}

export default otherReducer