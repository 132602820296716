/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import 'core-js/stable'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "./assets/scss/style.scss";
import { Provider } from "react-redux";
import store from "./redux/store";
import * as fetchCurrencyExchangeRatesActions from './redux/actions/fetchCurrencyExchangeRatesActions';
import * as partnerTypeActions from './redux/actions/partnerTypeActions';
import * as fetchCoursesActions from './redux/actions/fetchCoursesActions';

store.dispatch(partnerTypeActions.setPartnerType(window.PARTNER_TYPE));
store.dispatch(fetchCoursesActions.fetchCourses(window.PARTNER_TYPE));
store.dispatch(fetchCurrencyExchangeRatesActions.fetchCurrencyExchangeRatesActions());


ReactDOM.render(
  <Provider store={store}>
    <App />
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
