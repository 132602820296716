import * as types from '../actionTypes';

const initialState = {
    subscribedCrs: [],
    loading: false,
    apiSuccess: undefined,
    subscribedCourseLoading: false,
    subscribedCourseSuccess: undefined
}

function userReducer(state = initialState, action) {

    switch (action.type) {
        case types.SUBSCRIBEDCRS_REQUEST:
            return { ...state, loading: true }
        case types.SUBSCRIBEDCRS_SUCCESS:
            return { subscribedCrs: action.subscribedCrs, loading: false, apiSuccess: true }
        case types.SUBSCRIBEDCRS_FAILURE:
            return { subscribedCrs: [], apiSuccess: false, loading: false }
        case types.CLEARSUBSCRIBEDCRSSTATE:
            return { subscribedCrs: [], apiSuccess: undefined, loading: false }

        case types.SUBSCRIBE_MULTIPLE_COURSES_REQUEST:
            return { ...state, subscribedCourseLoading: true }
        case types.SUBSCRIBE_MULTIPLE_COURSES_SUCCESS:
            return { ...state, subscribedCourseSuccess: true, subscribedCourseLoading: false }
        case types.SUBSCRIBE_MULTIPLE_COURSES_FAILURE:
            return { ...state, subscribedCourseSuccess: false, subscribedCourseLoading: false }
        default:
            return state
    }
}


export default userReducer