const APIConstants = {
    // API_DESTINATION: (window.location.href.indexOf('.info') === -1)?'https://apps.contentenablers.com/storefront2020/index.php/' : 'https://appproxy.contentenablers.com/storefront2020/index.php/',
    API_DESTINATION: 'https://apps.contentenablers.com/storefront2020/index.php/',
    RECHARGE_SUB_URL: 'https://checkout.rechargeapps.com/r/checkout/',
    SHOPIFY_DOMAIN_NAME: 'content-enablers.myshopify.com',
    LOGIN_USER: 'user/lmsuser/authuser',
    SAVE_USER: 'user/lmsuser/saveuser',
    FORGOTPASSWORD_USER: 'user/lmsuser/forgotpasswordv2',
    RESETPASSWORD_USER: 'user/lmsuser/resetpassword',
    GETUSER_DETAILS: 'user/lmsuser/getuserbyid',
    UPDATEUSERPROFILE: 'user/lmsuser/updateuser',
    GETMYCOURSELIST: '/course/Lmscourse/getAssignedCourses',
    PURCHASESCRSURL: 'user/lmsuser/orderbycustomer',
    SUBSCIBEDCRSURL: 'recharge/subscriptions/subscriptionlistbycustomer',
    CANCELSUBSCRIPTION: 'recharge/subscriptions/cancelsubscription',
    SHOPIFY_ALL_PRODUCTS: '/shopify/products/allproducts',
    SHOPIFY_ALL_PRODUCTS_NEW: 'shopify/products/productsbypartner?id=',
    SHOPIFY_COURSE_BY_COLLECTION: '/shopify/products/productsByCollection/',
    RECHARGE_ALL_PRODUCTS: '/recharge/subscriptions/productslist',
    RECHARGE_ALL_PRODUCTS_NEW: '/recharge/subscriptions/productsbypartner?id=',
    MULTIPASS_VERIFY_ME: '/shopify/multipass/verifyme',
    RECHARGE_PRE_SUBSCRIPTION: '/recharge/subscriptions/rechargecheckout',
    RECHARGE_GUEST_SUBSCRIPTION: '/recharge/subscriptions/rechargecheckoutguest',
    RECHARGE_GUEST_FREE_SUBSCRIPTION: '/recharge/subscriptions/rechargecheckoutguestfree',
    RECHARGE_MULTIPLE_SUBSCRIPTION: '/recharge/subscriptions/rechargecheckoutmulti',
    CRYPTO_IV: '0123456789123456',
    CRYPTO_KEY: 'HG58YZ3CR9HG58YZ3CR93CR9',
    LMS_AUTOLOGIN: '//contentenablers.net/#/manageuser?id=',
    CRS_USER_SEAT: 'course/lmscourse/getCeStoreSeats',
    CURRENCY_EXCHANGE_RATES: (window.location.href.indexOf('.info') === -1)?'https://apps.contentenablers.com/storefront2020/index.php/shopify/products/currencyconv' : 'https://apps.contentenablers.com/storefront2020/index.php/shopify/products/currencyconv',
    LINKEDIN_APP_CLIENT_ID: "86gsxlkwld9z2p",
    LINKEDIN_APP_REDIRECT_URI: "https://staging.livingstone.contentenablers.com/sign-up",
    JURI_COL:[1,3],
    NONTAB:3,
    SWITCH_AVCOL:[2,5]
}
export default APIConstants;