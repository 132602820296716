import * as types from "../actionTypes"
import axios from "axios";
import APIConstants from "../../utils/APIConstants";

export const purchasedCourseRequest = () => {
    return {
        type: types.PURCHASEDCRS_REQUEST
    }
}

export const purchasedCourseSuccess = (purchasedCrsList) => {
    return {
        type: types.PURCHASEDCRS_SUCCESS,
        purchasedCrsList: purchasedCrsList,
        isLoggedIn: true
    }
}

export const purchasedCourseFailure = () => {
    return {
        type: types.PURCHASEDCRS_FAILURE
    }
}

export const getPurchasedCoursesDetails = (email) => {
    return (dispatch) => {
        dispatch(purchasedCourseRequest())
        axios.get(APIConstants.API_DESTINATION + APIConstants.PURCHASESCRSURL + '?email=' + email)
            .then(res => {
                if (res.data.status == "success") {
                    dispatch(purchasedCourseSuccess(res.data.data));
                } else {
                    dispatch(purchasedCourseFailure());
                }
            })
            .catch(err => {
                dispatch(purchasedCourseFailure())
            })

    }
}

export const clearPurchaseState = () => {
    return {
        type: types.CLEARPURCHASESTATE
    }
}