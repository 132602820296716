import * as types from '../actionTypes';

const initialState = {
    user: [],
    loading: false,
    apiSuccess: undefined,
    isUserExists : false
}

function forgotPasswordReducer(state = initialState, action) {
    switch (action.type) {
        case types.FORGOTPASSWORD_REQUEST:
            return { ...state, loading: true }
        case types.FORGOTPASSWORD_SUCCESS:
            return { loading: false, apiSuccess: true,isUserExists : true }
        case types.FORGOTPASSWORD_FAILURE:
            return { apiSuccess: false, loading: false,isUserExists : false }
        case types.CLEARFORGOTPSWDSTATE:
            return {apiSuccess : undefined,loading : false,isUserExists : false}
        default:
            return state
    }
}


export default forgotPasswordReducer