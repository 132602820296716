import * as types from "../actionTypes"
import axios from "axios";
import APIConstants from "../../utils/APIConstants";
import * as otherActions from '../../redux/actions/otherActions';


export const subscribedCourseRequest = () => {
    return {
        type: types.SUBSCRIBEDCRS_REQUEST
    }
}

export const subscribedCourseSuccess = (subscribedCrsList) => {
    return {
        type: types.SUBSCRIBEDCRS_SUCCESS,
        subscribedCrs: subscribedCrsList
    }
}

export const subscribedCourseFailure = () => {
    return {
        type: types.SUBSCRIBEDCRS_FAILURE
    }
}

export const getSubscribedCourseDetails = (email) => {
    return (dispatch) => {
        dispatch(subscribedCourseRequest())
        axios.get(APIConstants.API_DESTINATION + APIConstants.SUBSCIBEDCRSURL + '?email=' + email)
            .then(res => {
                if (res.data.status == "success") {
                    dispatch(subscribedCourseSuccess(res.data.data));
                } else {
                    dispatch(subscribedCourseFailure());
                }
            })
            .catch(err => {
                dispatch(otherActions.openModal("error",false));
                dispatch(subscribedCourseFailure())
            })

    }
}

export const subscribeMultipleCoursesRequest = () => {
    return {
        type: types.SUBSCRIBE_MULTIPLE_COURSES_REQUEST
    }
}

export const subscribeMultipleCoursesSuccess = () => {
    return {
        type: types.SUBSCRIBE_MULTIPLE_COURSES_SUCCESS
    }
}

export const subscribeMultipleCoursesFailure = () => {
    return {
        type: types.SUBSCRIBE_MULTIPLE_COURSES_FAILURE
    }
}

//subscribe a course
export const subscribeMultipleCourses = (cartObj, partnerType, email) => {

    return (dispatch) => {
        dispatch(subscribeMultipleCoursesRequest())
        console.log("cartObj", cartObj)
        let lineItems = []
        cartObj.forEach(item => lineItems.push({
            charge_interval_frequency: item.chargeIntervalFrequency,
            order_interval_frequency: item.orderIntervalFrequency,
            order_interval_unit: item.orderIntervalUnit.toLowerCase().includes("month".toLowerCase()) ? "month" : "day",

            product_id: item.productId,
            quantity: item.quantity,
            variant_id: item.shopifyVariantId,
            fulfillment_service: "manual",
            requires_shipping: true,
            taxable: true,
        }))

        let subOption = {
            email,
            lineItems
        }
        axios.post(APIConstants.API_DESTINATION + APIConstants.RECHARGE_MULTIPLE_SUBSCRIPTION, subOption)
            .then(res => {
                let apiRes = (res.data)
                if (apiRes.status == "success") {
                    if (apiRes.data.checkout.token) {
                        dispatch(subscribeMultipleCoursesSuccess())
                            window.location.href = APIConstants.RECHARGE_SUB_URL + apiRes.data.checkout.token + "?myshopify_domain=" +
                                APIConstants.SHOPIFY_DOMAIN_NAME + "&email=" + apiRes.data.checkout.email;
                    }
                }
            })
            .catch(err => {
                console.log(err)
                dispatch(otherActions.openModal("error",false));
                dispatch(subscribeMultipleCoursesFailure())
            })

    }
}
//subscribe a Guest checkout courses
export const subscribeGuestMultipleCourses = (cartObj, partnerType, email) => {

    return (dispatch) => {
        dispatch(subscribeMultipleCoursesRequest())
        let lineItems = []
        cartObj.forEach(item => lineItems.push({
            charge_interval_frequency: item.chargeIntervalFrequency,
            order_interval_frequency: item.orderIntervalFrequency,
            order_interval_unit: item.orderIntervalUnit.toLowerCase().includes("month".toLowerCase()) ? "month" : "day",
            product_id: item.productId,
            quantity: item.quantity,
            variant_id: item.shopifyVariantId,
            fulfillment_service: "manual",
            requires_shipping: true,
            taxable: true,
        }))

        let subOption = {
            lineItems
        }
        axios.post(APIConstants.API_DESTINATION + APIConstants.RECHARGE_GUEST_SUBSCRIPTION, subOption)
            .then(res => {
                let apiRes = (res.data)
                if (apiRes.status === "success") {
                    if (apiRes.data.checkout.token) {
                        dispatch(subscribeMultipleCoursesSuccess())
                            window.location.href = APIConstants.RECHARGE_SUB_URL + apiRes.data.checkout.token;
                    }
                }
            })
            .catch(err => {
                console.log(err)
                dispatch(otherActions.openModal("error",false));
                dispatch(subscribeMultipleCoursesFailure())
            })

    }
}

// Subscribe Guest checkout with Free trail course 
export const subscribeGuestFreeMultipleCourses = (cartObj, partnerType, email) => {

    return (dispatch) => {
        dispatch(subscribeMultipleCoursesRequest())
        let lineItems = []
        cartObj.forEach(item => lineItems.push({
            charge_interval_frequency: item.chargeIntervalFrequency,
            order_interval_frequency: item.orderIntervalFrequency,
            order_interval_unit: item.orderIntervalUnit.toLowerCase().includes("month".toLowerCase()) ? "month" : "day",
            product_id: item.productId,
            quantity: item.quantity,
            variant_id: item.shopifyVariantId,
            fulfillment_service: "manual",
            requires_shipping: true,
            taxable: true,
        }))

        let subOption = {
            lineItems
        }
        axios.post(APIConstants.API_DESTINATION + APIConstants.RECHARGE_GUEST_FREE_SUBSCRIPTION, subOption)
            .then(res => {
                let apiRes = (res.data)
                if (apiRes.status === "success") {
                    if (apiRes.data.checkout.token) {
                        dispatch(subscribeMultipleCoursesSuccess())
                            window.location.href = APIConstants.RECHARGE_SUB_URL + apiRes.data.checkout.token;
                    }
                }
            })
            .catch(err => {
                console.log(err)
                dispatch(otherActions.openModal("error",false));
                dispatch(subscribeMultipleCoursesFailure())
            })

    }
}

//subscribe a course
export const subscribeCourse = (handle, partnerType, email) => {
    return (dispatch) => {
        let handleObj = handle.split('||')
        let subOption = {
            email: email,
            charge_interval_frequency: 30,
            order_interval_frequency: 30,
            order_interval_unit: 'month',
            product_id: Number(handleObj[1]),
            quantity: 1,
            discount_code: '',
            variant_id: Number(handleObj[0])
        }
        axios.post(APIConstants.API_DESTINATION + APIConstants.RECHARGE_PRE_SUBSCRIPTION, subOption)
            .then(res => {
                let apiRes = (res.data)
                if (apiRes.status == "success") {
                    if (apiRes.data.checkout.token) {
                        window.location.href = APIConstants.RECHARGE_SUB_URL + apiRes.data.checkout.token + "?myshopify_domain=" +
                            APIConstants.SHOPIFY_DOMAIN_NAME + "&email=" + apiRes.data.checkout.email;
                    }
                }
            })
            .catch(err => {

            })

    }
}


export const clearSubscriptionState = () => {
    return {
        type: types.CLEARSUBSCRIBEDCRSSTATE
    }
}
