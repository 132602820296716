import * as types from '../actionTypes';

const initialState = {
    user: [],
    loading: false,
    apiSuccess: false,
    isPasswordReset : false
}

function resetPasswordReducer(state = initialState, action) {
    switch (action.type) {
        case types.RESETPASSWORD_REQUEST:
            return { ...state, loading: true }
        case types.RESETPASSWORD_SUCCESS:
            return { loading: false, apiSuccess: true,isPasswordReset : true }
        case types.RESETPASSWORD_FAILURE:
            return { apiSuccess: false, loading: false,isPasswordReset : false }
        default:
            return state
    }
}


export default resetPasswordReducer